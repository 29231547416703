import React, { useEffect, useState } from "react";
import axios from "axios";
import { OrbitProgress } from "react-loading-indicators";
import "./History.css";

export default function History() {
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);

  const closeContactModal = () => {
    var modal = document.getElementById('attendance');
    modal.style.display = 'none';
    window.history.back();
  };
  useEffect(() => {

    const fetchAndProcessAttendanceData = async () => {
      const apiUrl = "https://ems.crs.prologicsolutions.com.np/api/attendanceDetail";
      const bearerToken = localStorage.getItem("token");
      if (!bearerToken) {
        console.error("Token is missing.");
        return;
      }
      const now = new Date();
      const start_date = new Date(now);
      start_date.setDate(start_date.getDate() - 30);
      const startDateString = start_date.toISOString().split("T")[0];
      const end_date = now.toISOString().split("T")[0];
      try {
        const response = await axios.post(
          apiUrl,
          { start_date: startDateString, end_date: end_date },
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data && Array.isArray(response.data.data)) {
          const processedData = await processAttendanceData(response.data.data);
          setAttendanceData(processedData);
        } else {
          console.error("Invalid response data:", response.data);
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error.message);
      }  finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };

    

  const processAttendanceData = async (data) => {
    const processedData = [];
    for (const entry of data) {
      const processedEntry = {
        id: entry.id,
        attendance_date: entry.attendance_date,
        clock_in: entry.clock_in,
        clock_out: entry.clock_out,
        emp_id: entry.emp_id,
        full_name: entry.full_name,
      };
      if (
        entry.clock_in_lat &&
        entry.clock_in_lon &&
        !isNaN(entry.clock_in_lat) &&
        !isNaN(entry.clock_in_lon)
      ) {
        const clockInLocation = await fetchLocation(entry.clock_in_lat, entry.clock_in_lon);
        processedEntry.clockInLocation = clockInLocation;
      }
      if (
        entry.clock_out_lat &&
        entry.clock_out_lon &&
        !isNaN(entry.clock_out_lat) &&
        !isNaN(entry.clock_out_lon)
        
      ) {
        const clockOutLocation = await fetchLocation(entry.clock_out_lat, entry.clock_out_lon);
        console.log(clockOutLocation);
        processedEntry.clockOutLocation = clockOutLocation;
      }
      processedData.push(processedEntry);
    }
    return processedData;
  };

  const fetchLocation = async (lat, lon) => {
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?access_token=${`sk.eyJ1IjoiZGlrc2hpdGEiLCJhIjoiY2x1Z20wbHVkMHYwMjJsb2Vta3QycXpuciJ9.wpkT6uzq92KjfBYbWg6Q6Q`}`
        );
        const location = response.data.features[1].place_name;
        const split_location = location.split(","); // Extract the first part before comma
        const neighborhood = split_location[0];
        const city = split_location[1];
      return (`${neighborhood}, ${city}`);
    } catch (error) {
      console.error("Error fetching location:", error.message);
      return "";
    }
  };
  fetchAndProcessAttendanceData();
}, []);

  return (
    <div className="container-fluid" id="attendance">
      <div className="row justify-content-center mt-5">
        <div className="col-md-12">
        <button className="close" onClick={closeContactModal}>&#8592; Back to home</button>

          <div className="leaveoutput">
            <h3 className="h3">Attendance History</h3>
            <hr />
            <div className="attendance-container">
            {loading ? ( // Show loading indicator while data is being fetched
                <div className="loading-container">
                  <OrbitProgress variant="bubble-dotted" color="rgb(239, 37, 37)" size="medium" text="" textColor="" />
                </div>
              ) : (
                attendanceData.map((attendance) => (
                  <div key={attendance.id} className="attendance-item">
                    <p className="describe"><strong>Date:</strong> {attendance.attendance_date}</p>
                    <p className="describe"><strong>Clock In:</strong> {attendance.clock_in} at {attendance.clockInLocation}</p>
                    {attendance.clockOutLocation && (
                      <p className="describe"><strong>Clock Out:</strong> {attendance.clock_out} at {attendance.clockOutLocation}</p>
                    )}
                    <hr />
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
