import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./LeaveOutput.css";
import Message from "./Message";
import { useNavigate } from "react-router-dom";

export default function Leave() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isHalf, setIsHalf] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const leaveData = {
      start_date: startDate,
      end_date: endDate,
      is_half: isHalf,
      leave_type_id: 0,
      description: "something",
    };
    const apiUrl = "https://ems.crs.prologicsolutions.com.np/api/addleave";
    const bearerToken = localStorage.getItem("token");

    if (!bearerToken) {
      console.error("Token is missing.");
      return;
    }

    try {
      const response = await axios.post(apiUrl, leaveData, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setShowModal(true);
        toast.success("Leave Submitted Successfully!");
        setStartDate("");
        setEndDate("");
        setIsHalf(0);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(error.response.data.error.message);
      } else {
        toast.error("Unexpected error");
      }
    }
  };

  const handleCheckboxChange = (e) => {
    setIsHalf(e.target.checked ? 1 : 0);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/main");
  };

  return (
    <>
      <div className="container" id="leave">
        <div className="row justify-content-center mt-5">
          <div className="col-md-12">
            <div className="leavefield">
              <h4>Leave Info</h4>
              <hr />
              <form onSubmit={handleSubmit}>
                <div className="form-group some">
                  <label className="label">Start Date</label> <br />
                  <input
                    type="date"
                    className="inputfield"
                    min={new Date().toISOString().split("T")[0]}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group some">
                  <label className="label">End Date</label> <br />
                  <input
                    type="date"
                    className="inputfield"
                    min={new Date().toISOString().split("T")[0]}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group some d-flex">
                  <input
                    type="checkbox"
                    id="isHalfCheckbox"
                    className=""
                    style={{ marginRight: "10px" }}
                    onChange={handleCheckboxChange}
                    checked={isHalf === 1}
                  />
                  <div style={{ flexGrow: 1 }}>
                    <label className="label">Half Day</label>
                  </div>
                </div>
                <button type="submit" className="submit_button">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <Message
          message="It is mandatory to apply for leave through our NAV system."
          onCancel={handleCloseModal}
        />
      )}
    </>
  );
}
