import React from "react"
import './ConfirmationModal.css'
export default function ConfirmationModal({ message, onConfirm, onCancel }){
return<>
   <div className="modal">
      <div className="modal-content">
        <p className="message">{message}</p>
        <div className="modal-buttons">
          <button onClick={onConfirm}>Confirm</button>
          <button onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
</>
}
