import './App.css';
import {
  BrowserRouter ,Routes,Route
} from "react-router-dom";
import Login from './components/Login';
import Main from './components/Main';
import Holiday from './components/holiday';
import Leave from './components/Leave';
import History from './components/History';
import UserDetail from './components/UserDetail';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
  <BrowserRouter>
    <ToastContainer /> 
  <Routes>
  <Route path="/" element={<Login/>}></Route>
  <Route path="/main" element={<Main/>}></Route>
  <Route path="/holiday" element={<Holiday/>}></Route>
  <Route path="/leave" element={<Leave/>}></Route>
  <Route path="/history" element={<History/>}></Route>
  <Route path='/userdetail' element={<UserDetail/>}></Route>

  </Routes>
  </BrowserRouter>
  );
}

export default App;
  