import {React, useEffect, useState} from "react";
import './UserDetail.css'
export default function UserDetail(){
  const [fullName, setFullName] = useState("");
  const [id, setId] = useState("");
  const [project, setProject] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [station, setStation] = useState("");


//   const [photoUrl, setPhotoUrl] = useState("");
  function closeContactModal() {
    var modal = document.getElementById('inform');
    modal.style.display = 'none';
    window.history.back();
  }
    useEffect(() => {
        // Retrieve user data from localStorage
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (userData) {
            setId(userData.emp_id);
            setProject(userData.project_name);
            setContact(userData.contact_num);
            setEmail(userData.email);
            setFullName(userData.full_name); 
            setDepartment(userData.department_name);
            setDesignation(userData.designation_name);
            setStation(userData.station_name);
            // setPhotoUrl(userData.image); 
        }
      }, []);
      return(
        <>
          <div className="container-fluid" id="inform">
    <div className="row justify-content-center mt-5">
      <div className="col-md-12">
        <button className="close" onClick={closeContactModal}>&#8592; Back to home</button>
        <div className="information">
            <h3 className="h3">Personal Information</h3>
            <hr/>
         <p className="describe"><strong>Employee Id:</strong> {id} </p>
         <p className="describe"><strong>Name:</strong>  {fullName}</p>
         <p className="describe"><strong>Contact No:</strong> {contact}</p>
         <p className="describe"><strong>Email:</strong> {email}</p>
         <p className="describe"><strong>Project:</strong> {project}</p>
         <p className="describe"><strong>Department:</strong> {department}</p>
         <p className="describe"><strong>Designation:</strong> {designation}</p>
         <p className="describe"><strong>Station:</strong> {station}</p>
            </div>
        </div>
    </div>
      </div>
        </>
      )
}