import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import logo from "../images/logo.png";
import axios from "axios";
import { toast } from 'react-toastify';

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const credentials = {
      email: email,
      password: password,
    };
    //post login data
    try{
        const response= await axios.post('https://ems.crs.prologicsolutions.com.np/api/employee/login', credentials);
        console.log('Response:', response);
        
    if (response.data.success) {

      localStorage.setItem('token', response.data.token.token);
        // Save entire user object in localStorage
        localStorage.setItem('userData', JSON.stringify(response.data.user));
      navigate('/main');
      toast.success('Login successful'); // Display success toast

    } else {
      console.error('Login failed:', response.data);
      throw new Error('Login failed');
    }
    }catch(error){
     console.error('Error during login', error.message);
     toast.error('Incorrect email or password'); // Display error toast

    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-center mt-5">
          <div className="col-md-12">
            <div className="wrap">
              <img className="large-img img-fluid" src={logo} alt="/" />
              <div className="border_main">
                <div className="start">
                  <h3>Welcome Back</h3>
                  <p>Sign in to your account</p>
                </div>
                <form action="" className="login" onSubmit={handleLogin}>
                  <div className="login__field">
                    <i className="login__icon fas fa-user"></i>
                    <input
                      type="text"
                      id="email"
                      className="login__input"
                      placeholder="User Id"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="login__field">
                    <i className="login__icon fas fa-lock"></i>
                    <input
                      type="password"
                      id="password"
                      className="login__input"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="login__field">
                    <button type="submit" className="subm">
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
