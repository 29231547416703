import React, { useEffect, useState, useCallback } from "react";
import "./Main.css";
import { Link } from "react-router-dom";
import axios from "axios";
import ConfirmationModal from "./ConfirmationModal";
import { toast } from "react-toastify";
import { ThreeDot } from "react-loading-indicators";
// Import your images
import defaultImage from "../images/image.png";
import leaveImage from "../images/calendar.png";
import holidayImage from "../images/holiday.png";
import historyImage from "../images/history.png";

export default function Main() {
  const [clockInTime, setClockInTime] = useState(null);
  const [clockOutTime, setClockOutTime] = useState(null);
  const [statusCheckInOut, setstatusCheckInOut] = useState(null);
  const [showCheckInModal, setShowCheckInModal] = useState(false);
  const [showCheckOutModal, setShowCheckOutModal] = useState(false);
  const [fullName, setFullName] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [projectName, setProjectName] = useState("");
  const [loadingCheckIn, setLoadingCheckIn] = useState(false); // Loading state for Check In button
  const [loadingCheckOut, setLoadingCheckOut] = useState(false);
  const [isClockInButtonDisabled, setIsClockInButtonDisabled] = useState(false);
  const [isClockOutButtonDisabled, setIsClockOutButtonDisabled] =
    useState(false);

  const fetchLocation = async (lat, lon) => {
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?access_token=${`sk.eyJ1IjoiZGlrc2hpdGEiLCJhIjoiY2x1Z20wbHVkMHYwMjJsb2Vta3QycXpuciJ9.wpkT6uzq92KjfBYbWg6Q6Q`}`
      );
      const location = response.data.features[1].place_name;
      const split_location = location.split(","); // Extract the first part before comma
      const neighborhood = split_location[0];
      const city = split_location[1];
      return `${neighborhood}, ${city},${location}`;
    } catch (error) {
      console.error("Error fetching location:", error.message);
      return "";
    }
  };

  useEffect(() => {
    // Retrieve user data from localStorage
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setFullName(userData.full_name); // Set the full name
      setPhotoUrl(userData.image); // Set the photo URL
      setProjectName(userData.project_name);
    }
    if (userData.image === null) {
      // Set another image URL if image value is null
      setPhotoUrl(defaultImage);
    } else {
      // Set the photo URL from user data
      setPhotoUrl(userData.image);
    }
  }, []);
  useEffect(() => {
    const fetchAttendanceData = async (status) => {
      const apiUrl =
        "https://ems.crs.prologicsolutions.com.np/api/attendanceDetail";
      const bearerToken = localStorage.getItem("token");
      if (!bearerToken) {
        console.error("Token is missing.");
        return;
      }
      try {
        const now = new Date();
        const start_date = now.toISOString().split("T")[0];
        const end_date = now.toISOString().split("T")[0];
        const response = await axios.post(
          apiUrl,
          { start_date: start_date, end_date: end_date },
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        const responseData = response.data;
        console.log(responseData); // Check the structure and contents of the entire response
        if (responseData.data && responseData.data.length > 0) {
          const firstAttendanceRecord = responseData.data[0];
          const clockInTime = firstAttendanceRecord.clock_in;
          const clockOutTime = firstAttendanceRecord.clock_out;

          setIsClockInButtonDisabled(clockInTime !== null);
          setIsClockOutButtonDisabled(clockOutTime !== null);
        }
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };
    fetchAttendanceData();
  }, [clockInTime, clockOutTime]);
  //Post data in api for clock in
  const handleClick = useCallback(async () => {
    const setLoading =
      statusCheckInOut === "clock_in" ? setLoadingCheckIn : setLoadingCheckOut;
    setLoading(true);

    const position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    const now = new Date();
    const date = now.toISOString().split("T")[0];
    const time = now.toLocaleTimeString("en-US", { hour12: false });
    const bssid = "54:37:bb:94:b9:ed";

    const newAttendanceData = {
      attendance_date: date,
      bssid: bssid,
      time: time,
      status: statusCheckInOut,
      lat: latitude,
      lon: longitude,
      address: await fetchLocation(latitude, longitude),
    };

    const apiUrl = "https://ems.crs.prologicsolutions.com.np/api/attendance";
    const bearerToken = localStorage.getItem("token");
    if (!bearerToken) {
      console.error("Token is missing.");
      // Handle the case where the token is missing or invalid
      return;
    }

    try {
      const response = await axios.post(apiUrl, newAttendanceData, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          "Content-Type": "application/json",
        },
      });
      if (statusCheckInOut === "clock_in") {
        setClockInTime(time);
      } else if (statusCheckInOut === "clock_out") {
        setClockOutTime(time);
      }
      toast.success(response.data.success.message);
      // Refresh the page after successful check-in or check-out
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(error.response.data.error.message);
      } else {
        toast.error("Unexpected error");
      }
      console.error("Error during API request:", error.message);
    } finally {
      setLoading(false);
    }
  }, [statusCheckInOut]);
  useEffect(() => {
    if (statusCheckInOut) {
      handleClick();
    }
  }, [statusCheckInOut, handleClick]);

  useEffect(() => {
    function updateDateTime() {
      const now = new Date();
      const currentDate = now.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      const currentTime = now.toLocaleTimeString();

      document.querySelector(
        "#date"
      ).innerHTML = `<span>🗓 ${currentDate}</span>`;
      document.querySelector(
        "#time"
      ).innerHTML = `<span>⏱ ${currentTime}</span>`;
    }

    // call the `updateDateTime` function when the component mounts
    updateDateTime();

    // call the `updateDateTime` function every second
    const intervalId = setInterval(updateDateTime, 1000);

    // clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-center mt-5">
          <div className="col-md-12">
            <div className="wrapper">
              <div className="nav">
                <div className="user-profile">
                  <div className="user-photo-container">
                    <img className="user-photo" src={photoUrl} alt="/" />
                  </div>
                  <Link className="user-name" to="/userdetail">
                    {fullName}
                  </Link>
                </div>
                {/* <Link className="history" to="/history">
                  <img src={historyImage} alt="/" />
                </Link> */}
              </div>
              <div className="border-align">
                <span id="date"></span>
                <span id="time"></span>
                <p className="para">General 9:00 AM to 5:30 PM </p>
                <div className="d-flex buttonof">
                  <button
                    type="submit"
                    className={
                      loadingCheckIn || isClockInButtonDisabled
                        ? "submit disabled"
                        : "submit"
                    }
                    onClick={() => {
                      setShowCheckInModal(true);
                    }}
                    disabled={loadingCheckIn || isClockInButtonDisabled}
                  >
                    {loadingCheckIn ? (
                      <ThreeDot
                        variant="pulsate"
                        color="#198754"
                        size="large"
                        text="Loading"
                      />
                    ) : (
                      <>
                        <span className="gt-symbol">&#62;</span> Check In
                      </>
                    )}
                  </button>
                  {showCheckInModal && (
                    <ConfirmationModal
                      message="Are you sure you want to check in?"
                      onConfirm={() => {
                        setstatusCheckInOut("clock_in");
                        setShowCheckInModal(false);
                        // refreshPage();
                      }}
                      onCancel={() => setShowCheckInModal(false)}
                    />
                  )}
                  <button
                    type="submit"
                    className={
                      loadingCheckOut || isClockOutButtonDisabled
                        ? "button disabled"
                        : "button"
                    }
                    onClick={() => {
                      console.log("Check In button clicked");
                      setShowCheckOutModal(true);
                    }}
                    disabled={loadingCheckOut || isClockOutButtonDisabled}
                  >
                    {loadingCheckOut ? (
                      <ThreeDot
                        variant="pulsate"
                        color="#a10608"
                        size="large"
                        text="Loading"
                      />
                    ) : (
                      <>
                        Check Out <span className="gt-symbol">&#62;</span>
                      </>
                    )}
                  </button>
                  {showCheckOutModal && (
                    <ConfirmationModal
                      message="Are you sure you want to check out?"
                      onConfirm={() => {
                        setstatusCheckInOut("clock_out");
                        setShowCheckOutModal(false);
                      }}
                      onCancel={() => setShowCheckOutModal(false)}
                    />
                  )}
                </div>
              </div>
              {clockInTime && (
                <p style={{ display: "none" }}>Checked in at: {clockInTime}</p>
              )}
              {clockOutTime && (
                <p style={{ display: "none" }}>
                  Checked out at: {clockOutTime}
                </p>
              )}
              <div className="last">
                {projectName === "ARH" ? (
                  <>
                    <Link
                      className="holidayButton"
                      to="/leave"
                      style={{ textDecoration: "none" }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={leaveImage}
                          alt="/"
                          style={{ marginRight: "10px" }}
                        />
                        <span>Leave</span>
                        <i
                          className="fas fa-angle-right"
                          style={{ marginLeft: "auto" }}
                        ></i>
                      </div>
                    </Link>
                    <Link
                      className="holidayButton"
                      to="/holiday"
                      style={{ textDecoration: "none" }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={holidayImage}
                          alt="/"
                          style={{ marginRight: "10px" }}
                        />
                        <span>Holiday</span>
                        <i
                          className="fas fa-angle-right"
                          style={{ marginLeft: "auto" }}
                        ></i>
                      </div>
                    </Link>
                  </>
                ) : (
                  <Link
                    className="holidayButton"
                    to="/leave"
                    style={{ textDecoration: "none" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={leaveImage}
                        alt="/"
                        style={{ marginRight: "10px" }}
                      />
                      <span>Leave</span>
                      <i
                        className="fas fa-angle-right"
                        style={{ marginLeft: "auto" }}
                      ></i>
                    </div>
                  </Link>
                )}

                <Link
                  className="holidayButton"
                  to="/holiday"
                  style={{ textDecoration: "none" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={holidayImage}
                      alt="/"
                      style={{ marginRight: "10px" }}
                    />
                    <span>Holiday</span>
                    <i
                      className="fas fa-angle-right"
                      style={{ marginLeft: "auto" }}
                    ></i>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
