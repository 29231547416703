import React from "react"
import './Message.css'
export default function Message({ message,onCancel }){
return<>
   <div className="modal">
      <div className="modal-content">
        <p className="message">{message}</p>
        <div className="modal-button">
          <button onClick={onCancel}>Close</button>
        </div>
      </div>
    </div>
</>
}
